/*
 * (c) 2022 CARIAD SE, All rights reserved.
 *
 * NOTICE:
 * All the information and materials contained herein, including the intellectual and technical concepts,
 * are the property of CARIAD SE and may be covered by patents, patents in process, and are protected by trade secret and/or copyright law.
 * The copyright notice above does not evidence any actual or intended publication or disclosure of this source code, which includes information and materials
 * that are confidential and/or proprietary and trade secrets of CARIAD SE.
 * Any reproduction, dissemination, modification, distribution, public performance, public display of or any other use of this source code and/or any other
 * information and/or material contained herein without the prior written consent of CARIAD SE is strictly prohibited and in violation of applicable laws.
 * The receipt or possession of this source code and/or related information does not convey or imply any rights to reproduce, disclose or distribute its
 * contents or to manufacture, use or sell anything that it may describe in whole or in part.
 */

import { parseGet, parsePost, parsePut } from '@/utils/apiClient';
import {
  CmtCreateDeploymentPayload,
  DmDeployment,
  E311OruDeployment,
  E311OruDeploymentCpl,
  E311OruDeploymentCplPayload,
  E311OruDeploymentParameters,
  E311OruDeploymentParametersEntry,
  E311OruDeploymentVehiclePayload,
  E311OruDeploymentVehicleResponse,
} from '@/modules/campaign_management/models/E311Oru/DeploymentModels';
import { getCmtRequestAxiosConfig } from '@/modules/campaign_management/services/E311Oru/CmtAuthService';

const baseUrl = '/api/e3-1-1-oru-software/deployments';

export const getDeployment = (id: string) => parseGet(E311OruDeployment, `${baseUrl}/${id}`);
export const getDeployments = () => parseGet(DmDeployment.array(), `${baseUrl}`);
export const createDeployment = (payload: CmtCreateDeploymentPayload) =>
  parsePost(E311OruDeployment, `${baseUrl}`, payload, getCmtRequestAxiosConfig());

export const getDeploymentVehicles = (id: string) =>
  parseGet(E311OruDeploymentVehicleResponse, `${baseUrl}/${id}/vehicles`);

export const putSelectedVehicles = (id: string, { selectedVins }: E311OruDeploymentVehiclePayload) =>
  parsePut(E311OruDeploymentVehicleResponse, `${baseUrl}/${id}/vehicles`, selectedVins);

export const putConfirmSelectedVehicles = (id: string, vins: string[]) =>
  parsePut(E311OruDeploymentVehicleResponse, `${baseUrl}/${id}/vehicles/confirmation`, vins);

export const postWithdrawSelectedVehicles = (id: string) =>
  parsePost(E311OruDeploymentVehicleResponse, `${baseUrl}/${id}/vehicles/withdraw`);

export const getDeploymentCpl = (id: string) => parseGet(E311OruDeploymentCpl, `${baseUrl}/${id}/cpl`);

export const getDeploymentParameters = (id: string) =>
  parseGet(E311OruDeploymentParameters, `${baseUrl}/${id}/parameters`, getCmtRequestAxiosConfig());

export const putDeploymentParameters = (id: string, payload: E311OruDeploymentParametersEntry) =>
  parsePut(E311OruDeploymentParameters, `${baseUrl}/${id}/parameters`, payload, getCmtRequestAxiosConfig());

export const postDeploymentParametersConfirm = (id: string) =>
  parsePost(E311OruDeploymentParameters, `${baseUrl}/${id}/parameters/confirm`, getCmtRequestAxiosConfig());

export const postDeploymentParametersWithdraw = (id: string) =>
  parsePost(E311OruDeploymentParameters, `${baseUrl}/${id}/parameters/withdraw`, getCmtRequestAxiosConfig());

export const putDeploymentCpl = (id: string, payload: E311OruDeploymentCplPayload) =>
  parsePut(E311OruDeploymentCpl, `${baseUrl}/${id}/cpl`, payload, getCmtRequestAxiosConfig());

export const postDeploymentCplConfirm = (id: string) => parsePost(E311OruDeploymentCpl, `${baseUrl}/${id}/cpl/confirm`);

export const postDeploymentCplWithdraw = (id: string) =>
  parsePost(E311OruDeploymentCpl, `${baseUrl}/${id}/cpl/withdraw`);
export const postRolloutDeployment = (id: string) =>
  parsePost(E311OruDeployment, `${baseUrl}/${id}/rollout`, undefined, getCmtRequestAxiosConfig());
