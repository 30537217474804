/*
 * (c) 2022 CARIAD SE, All rights reserved.
 *
 * NOTICE:
 * All the information and materials contained herein, including the intellectual and technical concepts,
 * are the property of CARIAD SE and may be covered by patents, patents in process, and are protected by trade secret and/or copyright law.
 * The copyright notice above does not evidence any actual or intended publication or disclosure of this source code, which includes information and materials
 * that are confidential and/or proprietary and trade secrets of CARIAD SE.
 * Any reproduction, dissemination, modification, distribution, public performance, public display of or any other use of this source code and/or any other
 * information and/or material contained herein without the prior written consent of CARIAD SE is strictly prohibited and in violation of applicable laws.
 * The receipt or possession of this source code and/or related information does not convey or imply any rights to reproduce, disclose or distribute its
 * contents or to manufacture, use or sell anything that it may describe in whole or in part.
 */

import { z } from 'zod';
import {
  CommonDeploymentListEntry,
  CommonDeploymentStatusEnum,
  CommonDmDeploymentListEntry,
  StatusIconEnum,
} from '@/modules/campaign_management/models/CommonModels';

export const VehicleStatusEnum = z.enum(['ACTIVE']);

export const ZodTypeEnum = z.enum(['PHYSICAL_VEHICLE']);

export const E311OruDeployment = CommonDmDeploymentListEntry.extend({
  description: z.string().optional(),
  deploymentRequestId: z.string(),
  state: z.object({
    cpl: StatusIconEnum,
    parameters: StatusIconEnum,
    testVehicles: StatusIconEnum,
  }),
});

export const E311OruDeploymentListEntry = CommonDeploymentListEntry.extend({
  status: CommonDeploymentStatusEnum.optional(),
  deploymentRequestId: z.string(),
});
export type E311OruDeployment = z.infer<typeof E311OruDeployment>;

export const E311OruDeploymentVehicleListEntry = z.object({
  vin: z.string(),
  model: z.string(),
  year: z.number().optional(),
  department: z.string(),
  type: ZodTypeEnum,
  status: VehicleStatusEnum,
});
export type E311OruDeploymentAvailableVehicleListEntry = z.infer<typeof E311OruDeploymentVehicleListEntry>;
export type E311OruDeploymentSelectedVehicleListEntry = Pick<
  E311OruDeploymentAvailableVehicleListEntry,
  'vin' | 'model' | 'year' | 'status'
>;

export const E311OruDeploymentVehicleVins = z.string().array();
export const E311OruDeploymentVehiclePayload = z.object({
  selectedVins: E311OruDeploymentVehicleVins,
});
export type E311OruDeploymentVehiclePayload = z.infer<typeof E311OruDeploymentVehiclePayload>;

export const E311OruDeploymentVehicleResponse = E311OruDeploymentVehiclePayload.extend({
  availableVehicles: E311OruDeploymentVehicleListEntry.array(),
  status: StatusIconEnum,
  targetVehiclesCompletionDateTime: z.string().optional(),
});
export type E311OruDeploymentVehicleResponse = z.infer<typeof E311OruDeploymentVehicleResponse>;

export const E311OruDeploymentCplEntry = z.object({
  id: z.string(),
  productId: z.string(),
  ecuDiagnosticAddress: z.string(),
  hwRefPartNumber: z.string().optional(),
  hwVersion: z.string().optional(),
  sourceSparePartNumber: z.string(),
  sourceSwVersion: z.string(),
  targetSparePartNumber: z.string(),
  targetSwVersion: z.string(),
  targetDocumentId: z.string().optional(),
  isEnabled: z.boolean(),
});
export type E311OruDeploymentCplEntry = z.infer<typeof E311OruDeploymentCplEntry>;

export const E311OruDeploymentCpl = z.object({
  status: StatusIconEnum,
  confirmDate: z.string().optional(),
  isConfirmable: z.boolean(),
  cpl: E311OruDeploymentCplEntry.array(),
});

export const InstallationFailureActionEnum = z.enum(['CONTINUE', 'STOP']);
export const EnabledPowerGridEnum = z.enum(['0', '2']);

export const E311OruDeploymentParametersEntry = z.object({
  flashDuration: z.number(),
  flashDurationHv: z.number(),
  flashDurationHmi: z.number(),
  enabledPowerGrid: EnabledPowerGridEnum,
  currentConsumption: z.number(),
  currentConsumptionHv: z.number(),

  communicationControlUsed: z.boolean(),
  ecuResetAfterOruUsed: z.boolean(),
  eraseEventMemoryAfterOruUsed: z.boolean(),
  eraseObdEventMemoryAfterOruUsed: z.boolean(),
  oruWarningLightUsed: z.boolean(),
  inventoryValidation: z.boolean(),
  blockFlashAttemptsCtrMax: z.number(),
  flashAttemptsCtrMax: z.number(),
  flashProcessRepeatCtrMax: z.number(),
  repeatCtrMax: z.number(),
  retryCtrMax: z.number(),
  timeDelayGeneral: z.number(),
  timeDelayStart1: z.number(),
  timeDelayStart2: z.number(),
  timeDelayWaitForSleep: z.number(),
  logLevel: z.number(),
  description: z.string().optional(),
  htmlUri: z.string().optional(),
  installationFailureAction: InstallationFailureActionEnum,

  electricChargeQuota: z.boolean(),
  flashBlockCheck: z.boolean(),
  tuning: z.boolean(),
  downgradeProtection: z.boolean(),
  countryAssignment: z.boolean(),
  programmingAttempts: z.boolean(),
});
export type E311OruDeploymentParametersEntry = z.infer<typeof E311OruDeploymentParametersEntry>;

export type E311OruDeploymentParametersEnergyPayload = Pick<
  E311OruDeploymentParametersEntry,
  | 'flashDuration'
  | 'flashDurationHv'
  | 'flashDurationHmi'
  | 'enabledPowerGrid'
  | 'currentConsumptionHv'
  | 'currentConsumption'
>;
export type E311OruDeploymentParametersOverrulingPayload = Pick<
  E311OruDeploymentParametersEntry,
  | 'electricChargeQuota'
  | 'flashBlockCheck'
  | 'tuning'
  | 'downgradeProtection'
  | 'countryAssignment'
  | 'programmingAttempts'
>;
export type E311OruDeploymentParametersSpecificPayload = Pick<
  E311OruDeploymentParametersEntry,
  | 'blockFlashAttemptsCtrMax'
  | 'flashAttemptsCtrMax'
  | 'flashProcessRepeatCtrMax'
  | 'communicationControlUsed'
  | 'ecuResetAfterOruUsed'
  | 'eraseEventMemoryAfterOruUsed'
  | 'eraseObdEventMemoryAfterOruUsed'
  | 'repeatCtrMax'
  | 'retryCtrMax'
  | 'timeDelayGeneral'
  | 'timeDelayStart1'
  | 'timeDelayStart2'
  | 'oruWarningLightUsed'
  | 'logLevel'
  | 'inventoryValidation'
  | 'installationFailureAction'
  | 'timeDelayWaitForSleep'
  | 'description'
  | 'htmlUri'
>;

export const E311OruDeploymentParameters = z.object({
  status: StatusIconEnum,
  isConfirmable: z.boolean(),
  confirmDate: z.string().optional(),
  parameters: E311OruDeploymentParametersEntry,
  defaultParameters: E311OruDeploymentParametersEntry,
});
export type E311OruDeploymentParameters = z.infer<typeof E311OruDeploymentParameters>;

export type E311OruDeploymentCpl = z.infer<typeof E311OruDeploymentCpl>;

export type DeploymentGeneralCreatePayload = Pick<E311OruDeployment, 'name' | 'description'>;
export type CmtCreateDeploymentPayload = DeploymentGeneralCreatePayload & { deploymentRequestId: string };

export type E311OruDeploymentCplPayload = Array<
  Pick<E311OruDeploymentCplEntry, 'id' | 'targetDocumentId' | 'isEnabled'>
>;

export const DmDeployment = CommonDmDeploymentListEntry.extend({
  deploymentRequestId: z.string(),
});
export type DmDeployment = z.infer<typeof DmDeployment>;
